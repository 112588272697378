<template>
  <div class='sub-navigation' v-if="$route.matched.length === 1">
    <div class='section-title'>
        <div class="index">{{(index + 1).toString().padStart(2, '0')}}</div>
        <h1>{{title}}</h1>
    </div>
    <transition-group appear name="staggered-fade" tag="div" class="navigation" :css="false" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <router-link v-for="(item, id) in sections" :data-index="id" :key="item.name" :to="{name:item.pages[0].name, params:$route.params}" :style="{backgroundImage:`url(${item.pages[0].background})`}">
            <div>
                <div class="title">{{item.title}}</div>          
            </div>
        </router-link>       
    </transition-group>
  </div>
  <router-view class='child' v-else v-slot="{ Component, route }">
        <component :is="Component" :key="route.path" />
  </router-view>
</template>

<script>

export default {
  name: 'Sub Navigation',
  mounted() {
    if(this.$route.path === `/${this.$route.path.split('/')[1]}`) {
      this.$store.state.currentPage = {
        'next': this.sections[0].pages[0].name,
        'previous': 'Main Navigation'
      } 
    }
    /*
    this.$store.state.currentPage = {
      'next': {name:'Main Navigation', params:this.$route.params}
    }
    */
  },
  props: {
    sections:Array,
    index:Number,
    title:String,
  },  
  methods: {
    beforeEnter(el) {    
      el.style.opacity = 0
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        delay: el.dataset.index * 0.25,
        onComplete: done,
        duration:0.75
      })
    },
    leave(el, done) {
      gsap.to(el, {
        opacity: 0,
        delay: el.dataset.index * 0.5,
        onComplete: done
      })
    }
  }
}
</script>
<style lang="scss">
  .fade-enter-active {
    transition: all 0.8s ease-out;
  }

  .fade-leave-active {
    transition: all 0.3s;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
<style lang="scss" scoped>
.index {
    font-size: 3.125rem;
    font-family: 'Helvetica Neue LT Std';
    font-weight: 100;
}
h1{
    font-size: 2.125rem;
    text-transform: uppercase;
    font-family: 'Helvetica Neue LT Std';
    font-weight: 900;
}
.title {
    font-family: 'Helvetica Neue LT Std';
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 1;
    text-transform: uppercase;
}
.sub-navigation {    
    color: #F5F5F5;        
    background:url('~@/assets/images/bg_level-2.jpg');
    width:inherit !important;    
    position: relative;
    &:before {
      content:"";
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background: linear-gradient(270deg, rgba(18,18,18,0) 0%, #121212 100%);
    }
    @media (min-width:700px) {
        display: grid;
        grid-template-rows: 130px 1fr;
        align-items: center;
        justify-items: start;        
        padding:90px 130px;
        padding-right:0;
    }
    > div {
        width:100%;
        height:100%;
        @media (min-width:700px) {
          width:calc(100vw - 130px);;
          &.navigation:after {
            content:"";
            width:100px;
            display: block;
            flex-shrink: 0;
          }
        }
    }
    .section-title {        
        box-sizing: border-box;
        height:auto;
        margin-bottom:25px;
        padding:0 35px;
        position: relative;
        @media (min-width:700px) {
          padding:0;
          padding-right:130px;
        }
        &:after {
            content:"";
            height:1px;
            display: block;
            background-color:#0066CC;
        }
    }
}
.navigation {
    display:flex;
    gap:15px;
    flex-flow: column;
    @media (min-width:700px) {        
        gap:25px;
        flex-flow: row;
        overflow: auto;
        &::-webkit-scrollbar {
            height: 0;
        }
        a {
            width: 260px;
        }
    }
    a {
        background-size: cover;
        background-position: center;
        flex-shrink: 0;
        flex-grow: 0;        
        color: #F5F5F5;
        text-decoration: none;
        text-transform: uppercase;        
        border-radius: 5px;
        box-sizing: border-box;
        padding:35px;
        display: flex;
        align-items: flex-end;
        position: relative;
        max-height: 390px;
        -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
        color-adjust: exact; 
        &:before {
          box-sizing: border-box;
          content: "";
          position: absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(7,7,7,0.7) 100%);
          pointer-events: none;
          border:0px solid #F5F5F5;
          transition: 0.2s border;
        }
        > div {
          position: relative;
          width: 100%;
          border-bottom:1px solid #0066CC;
          padding-bottom:15px;
          user-select: none;
        }
        @media (hover: hover) {
          &:hover {
            &::before {
              border:10px solid #F5F5F5;
            }
          }
        }
    }
}
</style>
